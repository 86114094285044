import React from "react";
import ZeraInfo from "./Sections/ZeraInfo";
import NewReleases from "./Sections/NewReleases";
import "./Main.scss";

function Main() {
  return (
    <div className="wrapper">
      <main className="main__component">
        <div className="main__container">
          <ZeraInfo />
          <NewReleases />
        </div>
      </main>
    </div>
  );
}

export default Main;
