import React from "react";
import "./DefaultButton.scss";

function DefaultButton({ text, styles, variant = "" }) {
  return (
        <button
          type="button"
          onClick={() => {

          }}
          style={{ ...styles }}
          className={`default__button ${variant}`}
        >
          <div className='button__content'>
            <span>{text}</span>
          </div>
        </button>
  );
}

export default DefaultButton;
