import React from "react";
import "./FeatureItem.scss";

function FeatureItem({
  title,
  description,
  img,
  bigImage,
  reversed
}) {

  const renderText = () => {
    return (
      <div className="feature__text">
        {title && <h1 className="feature__title">{title}</h1>}
        {description && <div className="feature__desc">{description}</div>}
      </div>  
    )
  }

  const renderImage = () => {
    return (
      <div className={`feature__img-container ${bigImage ? "big" : ""}`}>
        <img src={img} alt="ZERA feature image" />
      </div>
    )
  }

  return (
    <div className={`feature__container ${reversed ? "reversed" : ""}`}>
      {renderText()}
      {renderImage()}
    </div>
  );
}

export default FeatureItem;
