import React from "react";
import "./AppItem.scss";

function AppItem({
  name,
  img,
}) {
  console.log("render")

  const renderText = () => {
    return (
      <div className="app__text">
        {name && <h1 className="app__title">{name}</h1>}
      </div>  
    )
  }

  const renderImage = () => {
    return (
      <div className={`app__img-container`}>
        <img src={img} alt="ZERA app image" />
      </div>
    )
  }

  return (
    <div className={`apps__container`}>
      {renderImage()}
      {renderText()}
    </div>
  );
}

export default AppItem;
