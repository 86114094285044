import React, { useRef, useState } from "react";
import "./Header.scss";
import { NavLink, useLocation } from "react-router-dom";

import DefaultButton from "../ReusableComponents/DefaultButton";

function Header() {
  const [isBurgerOpened, setIsBurgerOpened] = useState(false);

  const location = useLocation();
  const menuRef = useRef();

  const closeBurger = () => {
    document.body.classList.remove("lock");
    setIsBurgerOpened(false);
  };

  const toggleBurger = () => {
    isBurgerOpened
      ? document.body.classList.remove("lock")
      : document.body.classList.add("lock");

    setIsBurgerOpened(!isBurgerOpened);
  };
  return (
    <header className={`header`}>
      <div className="wrapper">
        <div
          onClick={toggleBurger}
          className={`burger-menu ${isBurgerOpened ? "opened" : ""}`}
        >
          <span></span>
        </div>
        <div className={`header__components`}>
          <nav className={`header__nav ${isBurgerOpened ? "opened" : ""}`}>
            <ul className="nav__list">
              <li className="nav__item">
                <NavLink
                  className={location.pathname === "/" ? "selected" : ""}
                  onClick={closeBurger}
                  to="#"
                >
                  Ecosystem
                </NavLink>
              </li>
              <li className={`nav__item`}>
                <NavLink
                  ref={menuRef}
                  className={
                    location.pathname === "/mission-statement" ||
                    location.pathname === "/our-story"
                      ? "selected"
                      : ""
                  }
                  onMouseEnter={null}
                >
                  Create Account
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  className={
                    location.pathname === "/features" ? "selected" : ""
                  }
                  onClick={closeBurger}
                  to="#"
                >
                  Zera Vision
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  className={location.pathname === "/careers" ? "selected" : ""}
                  onClick={closeBurger}
                  to="#"
                >
                  Apply (we're hiring)
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  className={
                    location.pathname === "/contact-us" ? "selected" : ""
                  }
                  onClick={closeBurger}
                  to="#"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>

        <div className="welcome__container">
          <div className="welcome__text">
            <h1 className="welcome__title">
              We Are Zera Vision
            </h1>
            <p className="welcome__subititle">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. 
            </p>
            <div className="buttons__container">
              <DefaultButton text={"ZERA Network Explorer"} variant="first" />
              <DefaultButton text={"Zera Vision Ecosystem"} variant="second" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
