import "./App.scss";
import Footer from "./components/ReusableComponents/Footer";
import Header from "./components/ReusableComponents/Header";
import Main from "./components/Main";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="full">
      <div className='background__main'>
        <Header />

        <div>
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
