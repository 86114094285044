import React from "react";
import "./ZeraInfo.scss";
import FeatureItem from "../ReusableComponents/FeatureItem";

import image1 from "../../img/Zgear1@2x.png";
import image2 from "../../img/zinovate@2x.png";
import image3 from "../../img/zsecure@2x.png";
import image4 from "../../img/zdecencopy@2x.png";
import image5 from "../../img/zpizzcopy@2x.png";

function ZeraInfo() {
  const row1 = {
    title: "Privacy Meets Ingenuity",
    description: "Zera Vision strives to create and contribute to innovative applications, services, and projects that foster utility and practicality for everyone.",
    img: image1,
  };

  const row2 = {
    title: "Innovation",
    description: "Leading the way in providing utility, applications, and services for our users.",
    img: image2
  };

  const row3 = {
    title: "Privacy Driven",
    description: "Privacy focused with proprietary database designs, zero-knowledge encryption, and more wherever possible.",
    img: image3
  };

  const row4 = {
    title: "Decentralized Contributors",
    description: "Contributing to ideas, projects, and technology that we believe in.",
    img: image4
  };

  const row5 = {
    title: "Contributing Partners",
    description: "Working with other decentralized contributors, companies, or individuals to integrate and make a difference.",
    img: image5
  };

  return (
    <section className="info__section">
      <FeatureItem {...row1} />
      <FeatureItem {...row2} reversed />
      <FeatureItem {...row3} />
      <FeatureItem {...row4} reversed />
      <FeatureItem {...row5} />
    </section>
  );
}

export default ZeraInfo;
