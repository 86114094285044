import React from "react";
import "./NewReleases.scss";
import AppItem from "../ReusableComponents/AppItem";

import zpayImg from "../../img/app_zpay@2x.png";
import explorerImg from "../../img/app_explorer@2x.png";

function NewReleases() {
  const apps = [
    {
      name: "Zpay",
      img: zpayImg,
    },
    {
      name: "Network Explorer",
      img: explorerImg,
    }
  ]

  return (
    <section className="apps__section">
      <h1>New Releases</h1>
      <div className="apps__section__container">
        <AppItem {...apps[0]}></AppItem>
        <AppItem {...apps[1]}></AppItem>
      </div>
    </section>
  );
}

export default NewReleases;
