import React from "react";
import "./Footer.scss";
import twitter from "../../img/twitter.svg";
import inst from "../../img/inst.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer__container">
          <div className="footer__header">
      
          </div>
          <div className="footer__body">
            <div className="footer__columns">
              {/* column 1 */}
              <div className="footer__column">
                <p className="footer__column-title">Zera Vision</p>
                <ul className="footer__list">
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Ecosystem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Zpay
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Network Explorer
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Zpay for Business
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Create Account
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Apply (we're hiring)
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              {/* column 2 */}
              <div className="footer__column">
                <p className="footer__column-title">Support</p>
                <ul className="footer__list">
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Terms of Service
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Cookies Policy
                    </a>
                  </li>
                </ul>
              </div>

              {/* column 3 */}
              <div className="footer__column footer__column-newsletter">
                {/* <ul className="social__list">
                  <li className="social__item">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/zera"
                      className="social__link"
                    >
                      <img src={inst} className="social__img" />
                    </a>
                  </li>
                  <li className="social__item">
                    <a
                      target="_blank"
                      href="https://twitter.com/zera"
                      className="social__link"
                    >
                      <img src={twitter} className="social__img" />
                    </a>
                  </li>
                </ul> */}

                <p className="footer__column-title">Sign up For Newsletter</p>
                <ul className="footer__list">
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <p>Zera Vision © 2023</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
